/* jshint strict: false */
(
    function (window, document, $, _, cookies, undefined) {
        function getAccessibilityModeCookie() {
            return !!+cookies.get('accessibilityMode');
        }

        function hideAccessibleElements() {
            $('.toggled-by-accessibility-mode').each(
                function () {
                    if (!$(this).hasClass('sr-only')) {
                        $(this).addClass('sr-only');
                    }
                }
            );

            $('.accessibility-toggle-status').each(
                function () {
                    if ($(this).attr('id') === 'disabled') {
                        $(this).removeClass('d-none')
                            .attr('aria-hidden', 'false');
                    } else {
                        $(this).addClass('d-none')
                            .attr('aria-hidden', 'true');
                    }
                }
            );
        }

        function showAccessibleElements() {
            $('.toggled-by-accessibility-mode').each(
                function () {
                    if ($(this).hasClass('sr-only')) {
                        $(this).removeClass('sr-only');
                    }
                }
            );

            $('.accessibility-toggle-status').each(
                function () {
                    if ($(this).attr('id') === 'enabled') {
                        $(this).removeClass('d-none')
                            .attr('aria-hidden', 'false');
                    } else {
                        $(this).addClass('d-none')
                            .attr('aria-hidden', 'true');
                    }
                }
            );
        }

        function toggleAccessibilityMode(accessible) {
            if (_.isUndefined(accessible) || !_.isBoolean(accessible)) {
                if (window.accessibilityMode) {
                    hideAccessibleElements();
                } else {
                    showAccessibleElements();
                }

                window.accessibilityMode = !window.accessibilityMode;
            } else if (_.isBoolean(accessible)) {
                if (accessible) {
                    showAccessibleElements();
                } else {
                    hideAccessibleElements();
                }

                window.accessibilityMode = accessible;
            }

            if (window.accessibilityMode !== getAccessibilityModeCookie()) {
                cookies.set('accessibilityMode', +window.accessibilityMode, { expires: (365 * 10) });
            }
        }

        function handleAccessibilityToggleClick(e) {
            e.preventDefault();
            toggleAccessibilityMode();
            $(this).toggleClass('active');
        }

        window.accessibilityMode = getAccessibilityModeCookie();

        toggleAccessibilityMode(window.accessibilityMode);

        $('.accessibility-toggle').on(
            'click.one-health',
            handleAccessibilityToggleClick
        )
            .toggleClass('active', window.accessibilityMode);
    }
)(window, document, window.jQuery, window._, window.Cookies);
