/*jshint strict: false */
(
    function (window, document, $, undefined) {
        $(document).ready(
            function () {
                $('.one-health-tooltip').tooltip();
            }
        );
    }
)(window, document, window.jQuery);
