/*jshint strict: false */
(
  function (window, document, $, undefined) {
    function doLogout () {
      document.getElementById('logout-form').submit();
    }

    function createConfirmModal () {
      var modalRoot = document.createElement('div'),
        modalDialog = document.createElement('div'),
        modalContent = document.createElement('div'),
        modalHeader = document.createElement('div'),
        modalFooter = document.createElement('div'),
        modalHeaderTitle = document.createElement('h5'),
        modalHeaderButton = document.createElement('button'),
        modalHeaderSpan = document.createElement('span'),
        modalFooterCancelButton = document.createElement('button'),
        modalFooterOkButton = document.createElement('button');

      modalRoot.setAttribute('class', 'modal fade');
      modalRoot.setAttribute('id', 'logoutModal');
      modalRoot.setAttribute('tabindex', '-1');
      modalRoot.setAttribute('aria-labelledby', 'loginModalHeading');
      modalRoot.setAttribute('aria-hidden', 'true');
      modalRoot.appendChild(modalDialog);

      modalDialog.setAttribute('class', 'modal-dialog');
      modalDialog.appendChild(modalContent);

      modalContent.setAttribute('class', 'modal-content');
      modalContent.appendChild(modalHeader);
      modalContent.appendChild(modalFooter);

      modalHeader.setAttribute('class', 'modal-header');
      modalHeader.appendChild(modalHeaderTitle);
      modalHeader.appendChild(modalHeaderButton);

      modalHeaderTitle.setAttribute('class', 'modal-title');
      modalHeaderTitle.setAttribute('id', 'loginModalHeading');
      modalHeaderTitle.innerHTML = 'Are you sure you want to sign out?';

      modalHeaderButton.setAttribute('class', 'close');
      modalHeaderButton.setAttribute('type', 'button');
      modalHeaderButton.setAttribute('data-dismiss', 'modal');
      modalHeaderButton.setAttribute('aria-label', 'Cancel');
      modalHeaderButton.appendChild(modalHeaderSpan);

      modalHeaderSpan.setAttribute('aria-hidden', 'true');
      modalHeaderSpan.innerHTML = '&times;';

      modalFooter.setAttribute('class', 'modal-footer');
      modalFooter.appendChild(modalFooterCancelButton);
      modalFooter.appendChild(modalFooterOkButton);

      modalFooterCancelButton.setAttribute('class', 'btn btn-secondary');
      modalFooterCancelButton.setAttribute('type', 'button');
      modalFooterCancelButton.setAttribute('data-dismiss', 'modal');
      modalFooterCancelButton.innerHTML = 'Cancel';

      modalFooterOkButton.setAttribute('class', 'btn btn-primary');
      modalFooterOkButton.setAttribute('type', 'button');
      modalFooterOkButton.innerHTML = 'OK';

      document.body.appendChild(modalRoot);

      var jqModal = $('#logoutModal');

      jqModal.modal(
        {
          show: false
        }
      );

      jqModal.on('show.bs.modal', window.toggleTabIndexes);
      jqModal.on('hide.bs.modal', window.toggleTabIndexes);

      modalFooterOkButton.addEventListener('click', doLogout);

      return jqModal;
    }

    function getModal () {
      var confirmModalElem = document.getElementById('logoutModal'),
        modalExists = confirmModalElem instanceof HTMLDivElement,
        confirmModal = null;

      if (modalExists) {
        confirmModal = $('#logoutModal');
      } else {
        confirmModal = createConfirmModal();
      }

      return confirmModal;
    }

    function showModal () {
      var confirmModal = getModal();

      if (confirmModal !== null) {
        confirmModal.modal('show');
      }
    }

    function hideModal () {
      var confirmModal = getModal();

      if (confirmModal !== null) {
        confirmModal.modal('hide');
      }
    }

    function logout (event) {
      event.preventDefault();

      showModal();

      /*window.bootbox.confirm(
        {
          size: 'small',
          message: 'Are you sure you want to sign out?',
          callback: function (result) {
            if (result) {
              document.getElementById('logout-form').submit();
            }
          },
          onShow: window.toggleTabIndexes,
          onHide: window.toggleTabIndexes
        }
      );*/
    }

    $(document).ready(
      function () {
        $('a.log-out-link').on(
          'click',
          logout
        );
      }
    );
  }
)(window, document, window.jQuery);
