/*jshint strict: false */
(
    function (window, document, $, undefined) {
        $(document).ready(
            function () {
                var dropdown = $('#searchToggler');

                dropdown.on(
                    'show.bs.dropdown',
                    function() {
                        $(this).find('.dropdown-menu')
                            .first()
                            .stop(true, true)
                            .slideDown(200);
                    }
                );

                dropdown.on(
                    'hide.bs.dropdown',
                    function() {
                        $(this).find('.dropdown-menu')
                            .first()
                            .stop(true, true)
                            .slideUp(200);
                    }
                );
            }
        );
    }
)(window, document, window.jQuery);
