/*jshint strict: false */
(
    function (window, document, $, _, cc, undefined) {
        $(document).ready(
            function () {
                cc.initialise(window.ccConfig);
            }
        );
    }
)(window, document, window.jQuery, window._, window.cookieconsent);
