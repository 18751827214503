/*jshint strict: false */
(
    function (window, document, $, _, undefined) {
        function courseViewedEvent(e) {
            e.preventDefault();

            var link = $(this).data('event-url'),
                course = $(this).data('course-id');

            $.ajax(
                {
                    url: link,
                    type: 'POST',
                    dataType: "json",
                    data: {
                        courseId: course
                    }
                }
            )
                .fail(
                    function (xhr, status, error) {
                        console.log(xhr);
                        console.log(status);
                        console.log(error);
                    }
                );
        }

        $(document).ready(
            function () {
                // Trigger events for courses viewed
                var courseLaunchers = $('.course-launcher');
                var courseLauncherParents = courseLaunchers.closest('.dashboard-course-block, .course-block');

                if (courseLauncherParents.length > 0) {
                    courseLauncherParents.on(
                        'click.one-health',
                        'a.course-launcher',
                        courseViewedEvent
                    );
                }
            }
        );
    }
)(window, document, window.jQuery, window._);
