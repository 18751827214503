/*jshint strict: false */
(
    function ($, undefined) {
        $.ajaxSetup(
            {
                headers: {
                    'X-Requested-With-Library': 'jQuery',
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            }
        );
    }
)(window.jQuery);
