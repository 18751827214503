/*jshint strict: false */
(
    function (window, document, $, undefined) {
        $(document).ready(
            function () {
                var desktopCountrySelector = $('#countrySelectorDesktop'),
                    mobileCountrySelector = $('#countrySelectorMobile'),
                    generateHideHandler = function (dataAttr) {
                        return function (e) {
                            if (_.has(e, 'clickEvent.target')) {
                                var clickedCountry = $(e.clickEvent.target);

                                if (!_.isUndefined(clickedCountry.data(dataAttr))) {
                                    $(this).find('a.dropdown-item').removeClass('active');
                                    clickedCountry.addClass('active');

                                    var ddToggleText = clickedCountry.data(dataAttr),
                                        otherTogglerText,
                                        otherToggler;

                                    if ($(this).hasClass('desktop')) {
                                        ddToggleText = 'Country: ' + ddToggleText;
                                        otherToggler = $('#' + $(this).attr('id').replace('Desktop', 'Mobile'));
                                        otherTogglerText = clickedCountry.data('country-flag');
                                    } else if ($(this).hasClass('mobile')) {
                                        otherTogglerText = 'Country: ' + clickedCountry.data('country-abbr');
                                        otherToggler = $('#' + $(this).attr('id').replace('Mobile', 'Desktop'));
                                    }

                                    if (!_.isUndefined(otherToggler)) {
                                        var otherTogglerItems = otherToggler.find('a.dropdown-item');
                                        otherTogglerItems.removeClass('active');
                                        otherTogglerItems.each(
                                            function (index, element) {
                                                if ($(element).data(dataAttr) === clickedCountry.data(dataAttr)) {
                                                    $(element).addClass('active');
                                                }
                                            }
                                        );
                                        otherToggler.find('a.dropdown-toggle').text(otherTogglerText);
                                    }

                                    $(this).find('a.dropdown-toggle')
                                        .text(ddToggleText);

                                        $.ajax(
                                            {
                                                url: clickedCountry.parent().data('change-action'),
                                                type: 'POST',
                                                dataType: "json",
                                                data: {
                                                    country: clickedCountry.data('country-abbr')
                                                }
                                            }
                                        )
                                            .done(
                                                function (data) {
                                                    var newPathParts = window.location.pathname.substr(1).split('/'),
                                                        newLocation;

                                                    newPathParts[0] = data.countryCode;
                                                        newLocation = window.location.origin + '/' + newPathParts.join('/');

                                                    window.location.href = newLocation;
                                                }
                                            )
                                            .fail(
                                                function (xhr, status, error) {
                                                    console.log(xhr);
                                                    console.log(status);
                                                    console.log(error);
                                                }
                                            );
                                }
                            }
                        };
                    };

                desktopCountrySelector.find('a.dropdown-toggle')
                    .text('Country: ' + desktopCountrySelector.find('.active').data('country-abbr'));

                desktopCountrySelector.on(
                    'hide.bs.dropdown',
                    generateHideHandler('country-abbr')
                );

                mobileCountrySelector.find('a.dropdown-toggle')
                    .text(mobileCountrySelector.find('.active').data('country-flag'));

                mobileCountrySelector.on(
                    'hide.bs.dropdown',
                    generateHideHandler('country-flag')
                );
            }
        );
    }
)(window, document, window.jQuery);
