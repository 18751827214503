/* jshint strict: false */
(
    function (window, document, $, _, cookies, undefined) {
        function toggleTabIndexes() {
            var modalOpen = $('body').hasClass('modal-open'),
                topNavLinks = $('header nav.navbar.top-nav a.nav-link'),
                marketNavLinks = $('header nav.navbar.market-nav a.nav-link, header nav.navbar.market-nav a.navbar-brand, header nav.navbar.mobile-nav a.navbar-brand'),
                navBarToggle = $('button.navbar-toggler'),
                mobileCountrySelector = $('header nav.navbar.mobile-nav .dropdown a.dropdown-toggle'),
                ctaLinks = $('.account-cta a.btn'),
                accountLinks = $('.account-menu a.btn, .account-menu a.nav-link'),
                certificatePrint = $('.print-certificate-block a.btn-print'),
                titleBarLinks = $('.market-modules-title-bar a'),
                certInfoLinks = $('.certificate-info a'),
                courseLinks = $('a.course-launcher'),
                accessibilityToggle = $('.accessibility-toggle'),
                addressBarLinks = $('footer .address-bar a'),
                logoBarLinks = $('footer .partner-logos a'),
                socialBarLinks = $('footer .copyright-social .social a'),
                allInputs = $('input'),
                allSelects = $('select'),
                allFormLinks = $('form a'),
                allFormButtons = $('form button'),
                allLinkButtons = $('a.btn'),
                /*allLinks = $().add(topNavLinks)
                    .add(marketNavLinks)
                    .add(navBarToggle)
                    .add(mobileCountrySelector)
                    .add(ctaLinks)
                    .add(accountLinks)
                    .add(certificatePrint)
                    .add(titleBarLinks)
                    .add(certInfoLinks)
                    .add(courseLinks)
                    .add(accessibilityToggle)
                    .add(addressBarLinks)
                    .add(logoBarLinks)
                    .add(socialBarLinks)
                    .add(allInputs)
                    .add(allSelects)
                    .add(allFormLinks)
                    .add(allFormButtons)
                    .add(allLinkButtons),*/
                allLinks = $('a, button, input, select, textarea')
                    .filter(
                        function () {
                            return $(this).closest('modal').length === 0;
                        }
                    );

            allLinks.each(
                function () {
                    var link = $(this);

                    if (modalOpen) {
                        if (_.has(link.data(), 'tabIndex')) {
                            link.attr('tabindex', link.data('tabIndex'));
                        } else {
                            link.attr('tabindex', 0);
                        }
                    } else {
                        if (_.isUndefined(link.attr('tabindex'))) {
                            link.attr('data-tab-index', '0');
                        } else {
                            link.attr('data-tab-index', link.attr('tabindex'));
                        }

                        link.attr('tabindex', '-1');
                    }
                }
            );
        }
        window.toggleTabIndexes = toggleTabIndexes;

        $(document).ready(
            function () {
                $('#marketCourseModal, #marketQuizModal, #marketVideoWebinarModal, #photoUploadModal, #disclaimerModal').on(
                    'show.bs.modal hide.bs.modal',
                    toggleTabIndexes
                );
            }
        );
    }
)(window, document, window.jQuery, window._, window.Cookies);
